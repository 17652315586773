// notice is global to all pages and is not a "module"
import { getFromMap, insertAfter, removeSelf } from './utilities'

const typeToBackgroundColor = {
  NOTICE: 'bg-green',
  WARNING: 'bg-red'
}

export function notice (document, appEventEmitter) {
  appEventEmitter.addEventListener('app-info', handleAppInfo)

  function addNotice (text, type = 'NOTICE', displayOnLandingPages, displayOnBlogPages) {
    const mainTag = document.querySelector('main')
    const breadcrumbs = document.querySelector('[data-js-breadcrumbs]')
    const isLandingPage = mainTag && mainTag.dataset.jsPageType === 'LANDING_PAGE'
    const isBlogPage = mainTag && mainTag.dataset.jsPageType === 'BLOG_PAGE'

    if ((isLandingPage && !displayOnLandingPages) || (isBlogPage && !displayOnBlogPages)) {
      return
    }

    const noticeEl = document.createElement('div')
    const typeClass = getFromMap(typeToBackgroundColor, type, 'bg-green')
    noticeEl.className = `${typeClass} notice flex items-center py-md md:pt-md px-xs sm:px-md md:px-lg text-white text-center leading-normal`

    addMessage(noticeEl, text)
    addDismissButton(noticeEl)

    if (breadcrumbs) {
      insertAfter(noticeEl, breadcrumbs)
      return
    }

    mainTag.prepend(noticeEl)
  }

  function addMessage (noticeEl, noticeText) {
    const messageEl = document.createElement('div')
    messageEl.className = 'w-full'
    messageEl.innerHTML = noticeText

    noticeEl.appendChild(messageEl)
  }

  function handleAppInfo ({ detail }) {
    const { websiteNotification } = detail
    const emptyNotification = !(websiteNotification && websiteNotification.copyText)
    if (emptyNotification || document.cookie.indexOf('scms_notice') !== -1 || document.cookie.indexOf('bdso_notice') !== -1) {
      return
    }

    addNotice(websiteNotification.copyText, websiteNotification.type, websiteNotification.displayOnLandingPages, websiteNotification.displayOnBlogPages)
  }

  function addDismissButton (noticeEl) {
    const buttonEl = document.createElement('button')
    buttonEl.className = 'p-sm md:pr-0 text-white cursor-pointer'
    buttonEl.addEventListener('click', () => dismissNotice(noticeEl))
    buttonEl.setAttribute('aria-label', 'Close')
    buttonEl.textContent = 'X'

    noticeEl.appendChild(buttonEl)
  }

  function dismissNotice (noticeEl) {
    const expDate = new Date()
    expDate.setTime(expDate.getTime() + (12 * 60 * 60 * 1000))
    const expDateString = expDate.toUTCString()
    document.cookie = `scms_notice=true;expires=${expDateString};path=/`

    removeSelf(noticeEl)
  }
}
