/*
 * faff.js is the entry point into the website code. It is responsible for application setup and loading all other javascript (modules) on the page by looking for selectors in the
 * HTML and importing the JS if it finds a match. In order to import scripts dynamically we are relying on Webpack https://webpack.js.org/guides/code-splitting/#dynamic-imports.
 * Webpack will end up creating a script file for each module on the page.
*/

/*
 * GLOBAL
 *
 * Global imports and application setup.
 */
// eslint-disable-next-line no-unused-vars
import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import { notice } from './js/notice'
import { appEventEmitter } from './js/app-event-emitter'
import { ctaConfigSwap } from './js/cta-config-swap'
import { callCta } from './js/call-cta'
import { quickLinks } from './js/quick-links'

notice(document, appEventEmitter)
ctaConfigSwap(document, appEventEmitter)
callCta(document, appEventEmitter)
quickLinks(document, appEventEmitter)
loadModules()

appEventEmitter.getPageCtaConfig()
appEventEmitter.getAppInfo() // Called after all other code so that code has a chance to attach event listeners before any dispatching happens.
appEventEmitter.addEventListener('run-faff', () => loadModules())

/*
 * MODULES (selector dependent code)
 *
 * A couple of guidelines for harmonious coding.
 * 1. Every module path is relative to "src/js/modules" since it is too confusing to group our javascript in the same directories as our React view layer and JS might span across multiple
 * React components / modules.
 * 2. Every module should default export a function that takes the "window.document", the selector HTML, and the app event emitter as the first 3 arguments. If the module needs
 * other data passed to it then the recommended approach is to use a path to a factory function instead of the path to the module.
 * 3. Modules should be self contained so generally accessing the DOM outside of the module selector is frowned upon.
 * 4. Modules selectors should be data attributes that starts with "data-js". This makes it clear what the javascript is using to hook into the DOM with. Other selectors like
 * classes, IDs, or elements already serve other purposes such as classes controlling styling and IDs being used for jump links. When we remove a style or jump link we do not
 * want to worry about our javascript breaking.
 */
function loadModules () {
  const modules = [
    {
      path: 'ppc-phone-swap',
      selector: '[data-js-page-type=LANDING_PAGE]'
    },
    {
      path: 'uuid-form-input',
      selector: 'input[name="uuid"]'
    },
    {
      path: 'fieldset-thank-you-pages',
      selector: '[data-js-outer-thank-you-pages]'
    },
    {
      path: 'carousel-factory',
      selector: '[data-js-carousel]'
    },
    {
      path: 'request-a-quote-form',
      selector: '[data-js-request-a-quote-form]'
    },
    {
      path: 'request-a-quote-reservation-confirmation',
      selector: '[data-js-request-a-quote-reservation-confirmation]'
    },
    {
      path: 'payment-confirmation',
      selector: '[data-js-payment-confirmation]'
    },
    {
      path: 'search-factory',
      selector: '[data-js-search]'
    },
    {
      path: 'video',
      selector: '[data-js-video]'
    },
    {
      path: 'navigation',
      selector: '[data-js-mobile-nav]'
    },
    {
      path: 'material-date-picker',
      selector: '[data-js-material-date-picker]',
      postInitAction () {
        return appEventEmitter.getHolidays(new Date(), 60)
      }
    },
    {
      path: 'address-autocomplete',
      selector: '[data-js-address-autocomplete]'
    },
    {
      path: 'address-autocomplete-fill',
      selector: '[data-js-address-autocomplete-fill]'
    },
    {
      path: 'phone-input-masking',
      selector: '[data-js-phone-input-masking]'
    },
    {
      path: 'alternate-schedule-a-pickup-multi',
      selector: '[data-js-alt-schedule-a-pickup-form-multi-container]'
    },
    {
      path: 'form-stepper',
      selector: '[data-js-form-stepper]'
    },
    {
      path: 'lightbox-modal',
      selector: '[data-js-lightbox-modal]'
    },
    {
      path: 'trustpilot-reviews',
      selector: '[data-js-trustpilot-review]'
    },
    {
      path: 'accordion-content',
      selector: '[data-js-accordion-content]'
    },
    {
      path: 'iframe-module',
      selector: '[data-js-iframe-module]'
    },
    {
      path: 'fieldset-suboptions',
      selector: '[data-js-fieldset-suboptions]'
    },
    {
      path: 'copy-to-clipboard',
      selector: '[data-js-copy-to-clipboard]'
    },
    {
      path: 'required-checkboxes',
      selector: 'input[type="checkbox"]:required'
    },
    {
      path: 'load-script',
      selector: '[data-js-load-script]'
    },
    {
      path: 'form-recaptcha',
      selector: '[data-js-load-script*="https://www.google.com/recaptcha/api.js"]'
    },
    {
      path: 'credit-card-input-masking',
      selector: '[data-js-credit-card-input-masking]'
    },
    {
      path: 'field-dependency',
      selector: '[data-js-dependent-field]'
    },
    {
      path: 'material-date-range-picker',
      selector: '[data-js-material-date-range]',
      postInitAction () {
        return appEventEmitter.getHolidays(new Date(), 60)
      }
    },
    {
      path: 'blog-search-results',
      selector: '[data-js-blog-search-results]'
    },
    {
      path: 'blog-search-form',
      selector: 'form[name="blog-search"]'
    },
    {
      path: 'calculator',
      selector: '[data-js-calculator]'
    },
    {
      path: 'field-prefill',
      selector: '[data-js-field-prefill]'
    },
    {
      path: 'form-feedback-review-request',
      selector: 'form[action$="forms/feedback"]'
    },
    {
      path: 'form-append-data',
      selector: '[data-js-append-form-data]'
    },
    {
      path: 'sticky-navigation',
      selector: '[data-js-sticky-navigation]'
    },
    {
      path: 'ab-test',
      selector: '[data-js-alt-urls]'
    }
  ]

  modules.forEach((module) => {
    const modulesHTML = document.querySelectorAll(module.selector)

    if (modulesHTML.length === 0) return

    import(
      // Magic Webpack comments documented here https://webpack.js.org/api/module-methods/#magic-comments.
      /* webpackChunkName: "[request]-[index]" */
      './js/faff-modules/' + module.path
    )
      .then(({ default: moduleFn }) => modulesHTML.forEach((moduleHTML) => moduleFn(document, moduleHTML, appEventEmitter)))
      .then(() => module.postInitAction && module.postInitAction())
  })
}
