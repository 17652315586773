// call-cta handles all spots where after hours affects what is displayed on screen. It is more a util
// for all modules and components, just add the attributes data-js-call-center-[open/close] depending
// on which does which. Up to you.

export function callCta (document, appEventEmitter) {
  appEventEmitter.addEventListener('app-info', handleAppInfo)

  function toggleCTAs (isOpen) {
    const callCenterOpen = document.querySelectorAll('[data-js-call-center-open]')
    const callCenterClosed = document.querySelectorAll('[data-js-call-center-closed]')

    callCenterOpen.forEach(elem => {
      const toggleClasses = elem.dataset.jsToggleClasses
      toggleClasses && toggleClasses.split(' ').forEach(className => elem.classList.toggle(className, isOpen))
      elem.classList.toggle('hidden', !isOpen)
    })
    callCenterClosed.forEach(elem => {
      const toggleClasses = elem.dataset.jsToggleClasses
      toggleClasses && toggleClasses.split(' ').forEach(className => elem.classList.toggle(className, !isOpen))
      elem.classList.toggle('hidden', isOpen)
    })
  }
  document.toggleCTAs = toggleCTAs // Attaching to document so this can be used in a bookmarklet

  function handleAppInfo ({ detail }) {
    toggleCTAs(detail.hoursOfOperation.isOpen)
  }
}
